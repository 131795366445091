import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

// import PageContentWrapper from "../../hoc/pagewrapper/pagewrapper";
import { MainContent, TextWrapper, Quote, GridRow } from "./aboutStyles";

import { Hero, CustomButton, Footer } from '../../components'
import { PageContentWrapper, AnimationWrapper } from '../../hoc'
import { withTheme } from 'styled-components'
import Loading from '../../components/loading/loading'

const AboutPage = ({theme}) => {
    const slug = 'about';
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data));
    }, []);

    if(!data) return <Loading />;

    return(
        <div>
            <Helmet>
                <title>{data.title} | James Mudge</title>
                <meta
                    name="description"
                    content={data.metadata.intro.intro_leading_text.replace(/(<([^>]+)>)/gi, "")}
                />
            </Helmet>
            <Hero 
                isCompact={true} 
                text={data.title}
                centerText 
                image={data.featured_image} 
            />
            <PageContentWrapper>
                <TextWrapper>
                    <div dangerouslySetInnerHTML={{ __html: data.metadata.intro.intro_leading_text }}></div>
                    <div dangerouslySetInnerHTML={{ __html: data.metadata.intro.intro_content }}></div>
                </TextWrapper>
                <MainContent theme={theme}>
                    <GridRow>
                        <img src={data.metadata.intro.intro_image_1.sizes.large} alt={data.metadata.intro.intro_image_1.alt} />
                        <AnimationWrapper>
                            <img src={data.metadata.intro.intro_image_2.sizes.large} alt={data.metadata.intro.intro_image_2.alt} />
                        </AnimationWrapper>
                    </GridRow>
                    <GridRow>
                        <div>
                            <h3>{data.metadata.design_ethos.design_ethos_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: data.metadata.design_ethos.design_ethos_content }}></div>
                        </div>
                        <Quote>
                            <p dangerouslySetInnerHTML={{ __html: data.metadata.design_ethos.design_ethos_pullquote }}></p>
                        </Quote>
                    </GridRow>
                    <GridRow>
                        <AnimationWrapper>
                            <div>
                                <img src={data.metadata.about_james.about_james_image_1.sizes.large} alt='james mudge portrait'/>
                            </div>
                        </AnimationWrapper>
                        <div>
                            <h3>{data.metadata.about_james.about_james_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: data.metadata.about_james.about_james_content }}></div>
                        </div>
                        <AnimationWrapper>
                            <img src={data.metadata.about_james.about_james_image_2.sizes.large} alt='table' />
                        </AnimationWrapper>
                   
                    </GridRow>
                 
                </MainContent>
                <AnimationWrapper>
                    <CustomButton to='/collections' text='Our Furniture' />
                </AnimationWrapper>
            </PageContentWrapper>
        </div>
    )
}

export default withTheme(AboutPage);
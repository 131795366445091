import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const GalleryContainer = styled.div`
    position: relative;
    // width: 100%;
    padding: 0 280px;

    @media screen and (max-width: 1680px) {
        padding: 0 208px;
    }

    @media screen and (max-width: 1540px) {
        padding: 0 192px;
    }

    @media screen and (max-width: 1280px) {
        padding: 0 144px;
    }

    @media screen and (max-width: 1024px) {
        padding: 0 96px;
    }

    @media screen and (max-width: 768px) {
        padding: 0 96px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
        padding: 0 40px;
    }

    &>h2{
        font-family: ${props => props.theme.fonts.primary};
        font-weight: 400;
        font-size: 32px;
        color: ${props => props.theme.colors.primary};
        margin: 0 0 48px;
        line-height: 36px;
        text-align: left;

    }
`

export const ItemsWrapper = styled.div`
    position: relative;
    width: 100%;
    // max-width: 1368px;
    margin: 0 auto;
    &>div{
        &:first-of-type, &:last-of-type{
            transition-delay: 100ms !important;
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        &>div{
            &:first-of-type, &:nth-of-type(2){
            display: none;
            }
        }
    }
`

export const GalleryItem = styled.div`
    width: 100%;
    text-decoration: none;
    outline: none;
    &:hover{
        
        img{
            transform: scale(1.05);
        }
    }
    h2{
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 16px;
        letter-spacing: 0.045em;
        line-height: 30px ;
        color: ${props => props.theme.colors.textLight};
        margin: 18px 0 0;
        font-weight: 400;
    }
    span{
        width: 100%;
        // max-height: 488px;
        display: block;
        overflow: hidden;
        // aspect-ratio: 3/4;
    }
    img{
        width: 100%;
        height: auto;
        // max-height: 488px;
        // aspect-ratio: 3/4;
        // object-fit: cover;
        transition: transform 800ms ease-out;
    }
`

export const GalleryGroup = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: ${props => props.active ? '0' : props.selfPosition < props.activePosition ? '-100%' : '100%'};
    // transform: ${props => props.active ? 'scale(1)' : 'scale(0)'};
    // display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 72px;
    justify-content: center;
    transition: 600ms ease-in-out;
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
        display: block;
        // width: 48%;
        // margin-right: 2%;
        left: ${props => props.active ? '0' : props.selfPosition-1 === props.activePosition ? '100%' : props.selfPosition < props.activePosition ? '-100%' : '100%'};
        // transform: ${props => props.active || props.selfPosition-1 === props.activePosition ? 'scale(1)' : 'scale(0)'} ;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.xsmall}){
        width: 100%;
        margin-right: unset ;
        left: ${props => props.active ? '0' : props.selfPosition < props.activePosition ? '-100%' : '100%'};
        // transform: ${props => props.active ? 'scale(1)' : 'scale(0)'};
    }
`

export const GalleryItems = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @media (max-width: ${props => props.theme.breakpoints.tablet}){
        grid-column-gap: 40px;
    }
    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        grid-template-columns: 1fr;
    }
`

export const ItemsOverflow = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  
`


export const Dummy = styled.div`
    width: 100%;
    grid-column-gap: 72px;
    justify-content: center;
    opacity: 0;
    user-select: none;
    // display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 72px;
    justify-content: center;
    pointer-events: none;
    &>div{
        width: 100%;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        grid-template-columns: 1fr;
        // width: 48%;
        // margin-right: 2%;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.xsmall}){
        width: 100%;
        margin: 0;
    }
`

export const Pagination = styled.div`
    // display: none;
    display: flex;
    margin-top: 32px;
    justify-content: center;
    min-height: 12px;
  
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        display: flex;
    }
`

export const PaginationItem = styled.span`
    width: 4px;
        height: 4px;
        border-radius: 50%;
        background: ${props => props.theme.colors.text};
        opacity: ${props => props.active ? '1' : '0.5'};
        transition: 300ms ease-in-out;
        margin: 0 8px;
        display: block;
`
import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router";
import { Helmet } from "react-helmet";

import Terms from './pages/terms/terms';
import Homepage from './pages/home/home';
import CategoryPage from './pages/category/category';
import CollectionPage from './pages/collection/collection';
import ProductPage from "./pages/product/product";
import AboutPage from "./pages/aboutUs/about";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

function App() {
  const location = useLocation();
  const [rooms, setRooms] = useState(null);
  const [collections, setCollections] = useState(null);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/rooms`, {
      method: 'GET',
      mode: 'cors'
    }).then(response => response.json())
      .then(data => setRooms(data));

    fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/collections`, {
      method: 'GET',
      mode: 'cors'
    }).then(response => response.json())
      .then(data => setCollections(data));
  }, []);

  useEffect(() => {
    setOpacity(0)
    window.scrollTo(0, 0, {
      behavior: 'instant'
    })
    setTimeout(() => setOpacity(1), 1000)
  }, [location])

  return (
    <div style={{ opacity: opacity }}>
      <Helmet>
        <title>James Mudge</title>
        <meta
          name="description"
          content="James Mudge Furniture Studio specialises in hand-made, solid-wood furniture that pairs contemporary design with traditional craftsmanship while maintaining the integrity of the raw material at its base."
        />
      </Helmet>
      <Header rooms={rooms} collections={collections} />
      <div style={{ minHeight: '100vh' }}>
        <Routes key={location.pathname} location={location}>
          <Route path="/" element={<Homepage />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/collections" element={<CategoryPage />} />
          <Route path="/products/:type/:category" element={<CollectionPage />} />
          <Route path='/products/:id' element={<ProductPage />} />
          <Route path='/about' element={<AboutPage />} />
        </Routes>
      </div>
      <Footer scroller={true} rooms={rooms} collections={collections} />
    </div>
  );
}

export default App;

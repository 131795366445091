import styled from "styled-components";

export const Column = styled.div`
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        display: ${props => props.isHiddenMobile ? 'none' : 'block'};
    }
    h3{
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 12px;
        line-height: 30px;
        margin: 0 0 32px;
        letter-spacing: 1.44px;
        text-transform: uppercase;
        font-weight: 400;
        color: ${props => props.theme.colors.textLight};
    }
`
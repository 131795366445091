import { Link } from 'react-router-dom';
import styled , {keyframes} from 'styled-components';


const appearAnimation = keyframes`
    from {
        opacity: 0;
        // transform: translateX(-72px);
    }
    to {
        opacity: 1;
        // transform: translateX(0);
    }
`

const disappearAnimation = keyframes`
    from {
        opacity: 1;
        // transform: translateX(0);
    }
    to {
        opacity: 0;
        // transform: translateX(-24px);
    }
`


export const HeaderContainer = styled.header`
    position: fixed;
    top: ${props => props.hide ? '-300px' : '0'};
    left: 0;
    width: 100%;
    z-index: 1000;
    background: ${(props) => {
        if(props.isInitial === false && props.hide === false) return 'white';
        else if(props.isOpen) return props.theme.colors.menuBG;
        else return 'transparent';
    }};
    transition: 400ms ease-out;
    min-height: 107px;

`

export const MainNavigation = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transition: 400ms ease-out;
    padding: 26px 0;
    box-sizing: border-box;
    width: 100%;
    z-index: 10001;
    &>div{
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    p{
        cursor: pointer;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        &>div{
            width: 77%;
        }
    }
`

export const Logo = styled.div`
    max-width: 220px;
    transition: 400ms ease-out;
    transition-delay: 0ms;
    opacity: ${props => props.isOpen ? '0' : '1'};
    overflow: hidden;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        max-width: 176px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        max-width: 176px;
    }

    img{
        width: 100%;
    }
`

export const MenuToggle = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    color: ${props => props.isOpen ? '#fff' : props.theme.colors.textLight };
    cursor: pointer;
    transition: 400ms ease-out;
    transition-delay: ${props => props.isOpen ? '300ms' : '0'};
    &>span{
        display: block;
        overflow: hidden;
        margin: 0 4px;
        transition: 400ms ease-out;
        transition-delay: ${props => props.isOpen ? '400ms' : '0'};
        color: ${props => props.isOpen ? '#fff' : props.theme.colors.textLight };
        font-size: 14px;
        font-family: ${props => props.theme.fonts.secondary};
        &:first-of-type{
            // max-width: ${props => props.isOpen ? '0' : '200px'};
        }
        &:last-of-type{
            // max-width: ${props => props.isOpen ? '200px' : '0'};
            opacity: ${props => props.isOpen ? '1' : '0'};
            transition: opacity 400ms;
        }
        &.icon {
            position: absolute;
            right: -20px;
            top: 1px;
        }
    }

`

export const StyledLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    outline: none;
    font-family: ${props => props.theme.fonts.primary};
    display: block;
    margin:  0 0 4px;
    font-size: 46px;
    font-weight: 400;
    letter-spacing: -0.92px;
    transition: color 400ms ease-out;
    &:hover{
        color: #8F8D8C;
    }
`


export const NavColumn = styled.div`
    h3{
        color: ${props => props.theme.colors.textLightOpaque};
        font-size: 13px;
        letter-spacing: 1.44px;
        margin: 0 0 32px;
        text-transform: uppercase;
        font-weight: 400;
        font-family: ${props => props.theme.fonts.secondary};
    }
    .group{
        color: #fff;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        p{
            font-family: ${props => props.theme.fonts.secondary};
            font-size: 20px;
            margin: 0;
        }
        .subgroup{
            margin-top: 32px;
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        h3{
            font-size: 13px;
            letter-spacing: 0.12em;
            line-height: 34px;
            margin: 0;
        }
        border-top: 1px solid #343434;
        .group{
            row-gap: 18px;
            p{
                font-size: 14px;
            }
        }
    }
`

export const SecondaryNavigation = styled.div`
    position: absolute;
    top: ${props => props.isOpen ? '0px' : '-100vh'};
    left: 0;
    z-index: 1000;
    width: 100vw;
    transition: 400ms ease-out;
    background: ${props => props.theme.colors.menuBG};
    height: 100vh;
    // transition-delay: ${props => props.isOpen ? '0ms' : '800ms'};
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        top: ${props => props.isOpen ? '-2px' : '-100vh'};
        height: calc( 100vh + 2px);
    }
    &>div{
        width: 80%;
        display: flex;
        margin-left: 140px;
        column-gap: 48px;
        margin-top: 158px;

        opacity: 0;
        // transform: translatex(-72px);
        animation: ${props => props.isOpen ? appearAnimation : disappearAnimation};
        animation-duration: 1200ms;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        // animation-delay: ${props => props.isOpen ? '800ms' : '0'};

        ${NavColumn}:last-of-type{
            margin-left: auto;
        }
        &>div{
            // opacity: 0;
            // transform: translatex(-72px);
            // animation: ${props => props.isOpen ? appearAnimation : disappearAnimation};
            // animation-duration: 1200ms;
            // animation-timing-function: ease-in-out;
            // animation-fill-mode: forwards;

            // &:nth-of-type(1){
            //     animation-delay: ${props => props.isOpen ? '850ms' : '0'};
            // }
            // &:nth-of-type(2){
            //     animation-delay: ${props => props.isOpen ? '700ms' : '0'};
            // }
            // &:nth-of-type(3){
            //     animation-delay: ${props => props.isOpen ? '550ms' : '0'};
            // }
            // &:nth-of-type(4){
            //     animation-delay: ${props => props.isOpen ? '400ms' : '0'};
            // }

        }
       
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        &>div{
            width: 80%;
            margin-left: 0;
            padding-left: 96px;
            box-sizing: border-box;
        }
        ${StyledLink}{
            font-size: 32px;
            letter-spacing: 0.02em;
            line-height: 44px;
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        &>div{
            width: 85%;
            flex-direction: column;
            
            margin-top: 96px;
            // padding-left: 40px;
            // padding-right: 40px;
            padding-left: 0;
            padding-right: 0;
            margin-left: auto;
            margin-right: auto;
        }   
        ${NavColumn}:last-of-type{
            margin-left: 0;
            width: 100%;
        }
    }
`

export const NavColumnContent = styled.div`
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        max-height: ${props => props.active ? "700px" : 0};
        overflow: hidden;
        // transition: 1200ms ease-out;
        margin-bottom: ${props => props.active ? "20px" : 0};
    }
    p {
        a {
            color: #fff;
            text-decoration: none;
        }
    }
`

export const NavCollapseHeading = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    span{
        display: none;
        background: #484848;
        position: absolute;
        transform-origin: center;
        transition: 1200ms ease-out;
        &:first-of-type{
            width: 100%;
            height: 1px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        &:last-of-type{
            width: 1px;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%) ${props => props.active ? 'scale(0)' : 'scale(1)'};
            
        }
    }
    div{
        position: relative;
        width: 16px;
        height: 16px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        justify-content: space-between;
        width: 100%;
        span{
            display: block;
        }
        padding: 16px 0;
        
    }
`


const theme = {
    colors: {
        text: '#221f20',
        textOpaque: '#575656',
        textLight: '#343434',
        textLightOpaque: '#8f8d8c',
        footerBG: '#f7f7f6',
        menuBG: '#1c1c1c'

    },
    fonts: {
        primary: 'Cormorant Garamond',
        secondary: 'NeueHaasDisplayRoman'
    },
    breakpoints: {
        xsmall: '568px',
        mobile: '768px',
        tablet: '1024px',
        desktop: '1280px',
        desktopLarge: '1540px',
        desktopXLarge: '1680px',
        fullHD: '1920px',
        fullHDPlus: '2560px'

    }
}

export default theme;

import { Dummy, GalleryContainer, ItemsWrapper, GalleryItem, GalleryItems, ItemsOverflow, GalleryGroup, Pagination, PaginationItem } from './homeGalleryStyles'
import { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import GalleryArrow from '../galleryArrow/galleryArrow'
import { RenderIf } from '../../hoc'


const HomeGallery = ({items, theme}) => {
    const [activeGroupPosition, setActiveGroupPosition] = useState(0)
    const [groups, setGroups] = useState([]);
    const chunksSize = 3;
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
            const distance = touchStart - touchEnd;
            const isLeftSwipe = distance > minSwipeDistance;
            const isRightSwipe = distance < -minSwipeDistance;
        if(isLeftSwipe && activeGroupPosition < items.length-1) setActiveGroupPosition(prevActiveGroupPosition => prevActiveGroupPosition+1);
        if(isRightSwipe && activeGroupPosition !== 0) setActiveGroupPosition(prevActiveGroupPosition => prevActiveGroupPosition-1);
    }

    useEffect(() => {
        const chunks = [];
        for (let i = 0; i < items.length; i += chunksSize) {
            chunks.push(items.slice(i, i + chunksSize));
        }
        setGroups(chunks);
    }, [items]);

    const getViewPortWidth = () => {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    return(
        <GalleryContainer theme={theme}>
            <h2>Shop by Room</h2>
            <ItemsWrapper>
                <GalleryArrow galleryHasTitles={true} position={'left'} show={activeGroupPosition > 0} theme={theme}  color={'#000'} action={() => setActiveGroupPosition(0)} />
                <GalleryArrow galleryHasTitles={true} position={'right'} show={activeGroupPosition < 1} theme={theme} color={'#000'} action={() => setActiveGroupPosition(1)}  />
                <Dummy>
                    <GalleryItem to='#'>
                        <img src={require('../../assets/images/Mask Group 73.webp')} alt=""/>
                        <h2>Living Room</h2>
                    </GalleryItem>
                </Dummy>
                <ItemsOverflow>
                    <GalleryItems onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                        
                        <RenderIf condition={getViewPortWidth() > 768}>
                            {groups.map((group, index) => {
                                return (
                                    <GalleryGroup key={index} active={activeGroupPosition === index} selfPosition={index} activePosition={activeGroupPosition}>
                                        {group.map((item, group_index) => {
                                            return (
                                                <GalleryItem key={group_index} to={`/products/rooms/${item.slug}`}>
                                                    <span>
                                                        <img src={item.metadata.listing_image.sizes.large} alt=""/>
                                                    </span>
                                                    <h2>{item.name}</h2>
                                                </GalleryItem>
                                            );
                                        })}
                                    </GalleryGroup>
                                );
                            })}
                        </RenderIf>

                        <RenderIf condition={getViewPortWidth() <= 768}>
                            {items.map((item, index) => {
                                return (
                                    <GalleryGroup key={index} active={activeGroupPosition === index} selfPosition={index} activePosition={activeGroupPosition}>
                                        <GalleryItem to={`/room/${item.slug}`}>
                                            <span>
                                                <img src={item.metadata.image.sizes.large} alt=""/>
                                            </span>
                                            <h2>{item.name}</h2>
                                        </GalleryItem>
                                    </GalleryGroup>
                                );
                            })}
                        </RenderIf>

                    </GalleryItems>
            
                </ItemsOverflow>
      
            </ItemsWrapper>
            <Pagination theme={theme}>
                {
                    Array(getViewPortWidth() > 568 ? 5 : 6).fill(0).map((_, index) => {
                        return <PaginationItem key={index} active={activeGroupPosition === index} onClick={() => setActiveGroupPosition(index)}></PaginationItem>
                    } )
                }
            </Pagination>
        </GalleryContainer>
    )
}

export default withTheme(HomeGallery)
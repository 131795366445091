import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import Fade from 'react-reveal'
import { CollectionGroup, FollowSection,  CollectionsWrapper, GridRow, NewsletterPopup } from "./homeStyles";
import { withTheme } from 'styled-components';
import { Helmet } from "react-helmet";

import { AnimationWrapper, PageContentWrapper, SectionWrapper } from '../../hoc'
import { Hero, CustomButton, FollowItem, InfoText } from '../../components'
import HomeGallery from '../../components/homeGallery/homeGallery'
import Loading from '../../components/loading/loading'
import { StyledForm } from '../../components/footer/footerStyles';
import closeIcon from '../../assets/images/close-icon.svg';

const instagram_id = '3283760827';
// 51321120976
const access_token = 'IGQVJXWWlUQjNRbks5NTZAKeXBEd05QeFdaZAkJya19kaGozLURGS1UwZAGtpcjJEc2I3ZAHN1SjFGRjN4cWJuMFJTczlUSUlsdmFWV2hDZAEdDMXgzOWJVRmxwNlNGVVU5bnA1SnhFYkRn';
// jamesmudgefurniturestudio

const Homepage = (props) => {
    const slug = 'home';
    const [data, setData] = useState(null);
    const [rooms, setRooms] = useState(null);
    const [instagramImages, setInstagramImages] = useState(null);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState(null);
    const [showNewsLetterPopup, setShowNewsLetterPopup] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        let email = e.target.email.value;
        if(email){
            fetch(`${process.env.REACT_APP_REST_API_URL}/add-email-to-mailing-list?email=${email}`, {
                method: 'GET',
                mode: 'cors'
            }).then(response => response.json())
                .then(data => {
                    if(data.success) {
                        setEmail("");
                        setMessage('Thank you!');
                    } else {
                        setMessage('Something went wrong. Please try again.');
                    }
                });
        }
    }

    const closePopup = () => {
        setShowNewsLetterPopup(false);
    }

    useEffect(() => {
        let last_visit = document.cookie.replace(/(?:(?:^|.*;\s*)last_visit\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        if(!last_visit) setShowNewsLetterPopup(true);
        let date = new Date();
        date.setTime(date.getTime() + (365*24*60*60*1000));
        document.cookie = "last_visit" + "=" + (Math.floor(Date.now() / 1000) || "") + "; expires=" + date.toUTCString() + "; path=/";
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data));

        fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/rooms`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setRooms(data));

        // fetch(`https://graph.instagram.com/v16.0/me/media?fields=media_url,permalink&access_token=${access_token}&limit=4`, {
        //     method: 'GET',
        //     mode: 'cors'
        // }).then(response => response.json())
        //     .then(images => setInstagramImages(images));
    }, []);

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    if(!data) return <Loading />;

    return(
        <div>
            <Helmet>
                <title>James Mudge</title>
                <meta
                    name="description"
                    content={data.metadata.intro.replace(/(<([^>]+)>)/gi, "")}
                />
            </Helmet>
            <Hero 
                isCompact={false} 
                image={data.metadata.desktop_images[getRandomInt(0, data.metadata.desktop_images.length-1)]['url']} 
                tablet_image={data.metadata.tablet_images[getRandomInt(0, data.metadata.tablet_images.length-1)]['sizes']['large']} 
                mobile_image={data.metadata.mobile_images[getRandomInt(0, data.metadata.mobile_images.length-1)]['sizes']['large']} 
                type={'scroll'} 
                linkTarget='about' 
            />
            <PageContentWrapper id='about'>
                <SectionWrapper id='aboutContainer'>
                    <AnimationWrapper revealFraction={0.5}>
                        <InfoText>
                            <div dangerouslySetInnerHTML={{ __html: data.metadata.intro }}></div>
                        </InfoText>
                    </AnimationWrapper>
                </SectionWrapper>
                <SectionWrapper revealFraction={0}>
                    <CollectionsWrapper theme={props.theme}>
                        <>
                            <div>
                                <AnimationWrapper>
                                    <div>
                                        <h1>Collections</h1>
                                        <CollectionGroup>
                                            <Link to={`/products/collections/${data.metadata.collections.collection_1_link.slug}`}>
                                                <span>
                                                    <img src={data.metadata.collections.collection_1_image.sizes.large} alt={data.metadata.collections.collection_1_image.alt} />
                                                </span>
                                                <h2>{data.metadata.collections.collection_1_title}</h2>
                                            </Link>
                                        </CollectionGroup>
                                    </div>
                                </AnimationWrapper>
                            
                                <AnimationWrapper>
                                    <CollectionGroup>
                                        <Link to={`/products/collections/${data.metadata.collections.collection_2_link.slug}`}>
                                            <span>
                                                <img src={data.metadata.collections.collection_2_image.sizes.large} alt={data.metadata.collections.collection_2_image.alt} />
                                            </span>
                                            <h2>{data.metadata.collections.collection_2_title}</h2>
                                        </Link>
                                    </CollectionGroup>
                                </AnimationWrapper>
                            </div>
                            <AnimationWrapper>
                                <CollectionGroup>
                                    <Link to={`/products/collections/${data.metadata.collections.collection_3_link.slug}`}>
                                        <span>
                                            <img src={data.metadata.collections.collection_3_image.sizes.large} alt={data.metadata.collections.collection_3_image.alt} />
                                        </span>
                                        <h2>{data.metadata.collections.collection_3_title}</h2>
                                    </Link>
                                </CollectionGroup>
                            </AnimationWrapper>
                            <AnimationWrapper>
                                <CustomButton text='All Collections' to='/collections' />
                            </AnimationWrapper>
                        </>
                    </CollectionsWrapper>
                
                </SectionWrapper>
                <SectionWrapper>
                    <GridRow theme={props.theme}>
                        <>
                            <div>
                                <img src={data.metadata.about.about_image_1.sizes.large} alt={data.metadata.about.about_image_1.alt} />
                            </div>
                            <div>
                                <h3>{data.metadata.about.about_title}</h3>
                                <p dangerouslySetInnerHTML={{ __html: data.metadata.about.about_intro }}></p>
                            </div>
                        </>
                        <AnimationWrapper>
                            <div>
                                <img src={data.metadata.about.about_image_2.sizes.large} alt={data.metadata.about.about_image_2.alt} />
                            </div>
                        </AnimationWrapper>
                    </GridRow>
                </SectionWrapper>

                <SectionWrapper>
                    <AnimationWrapper>
                        <InfoText>
                            <p dangerouslySetInnerHTML={{ __html: data.metadata.about.about_content }}></p>
                        </InfoText>
                        <CustomButton text='About Us' to='/about' />
                    </AnimationWrapper>
                </SectionWrapper>
                <SectionWrapper>
                    <AnimationWrapper>
                        <>
                            {rooms &&
                                <HomeGallery items={rooms.terms} />
                            }
                        </>
                    </AnimationWrapper>
                </SectionWrapper>
              
            </PageContentWrapper>
         
            <>
                {instagramImages &&
                    <AnimationWrapper>
                        <FollowSection theme={props.theme}>
                            <>
                                <h1>Follow Us</h1>
                                <div className="instagram">
                                    <>
                                        {instagramImages.data.map((image, index) => <FollowItem key={index} to={image.permalink} image={image.media_url}  />)}
                                    </>
                                </div>
                            </>
                        </FollowSection>
                    </AnimationWrapper>
                }
            </>

            {showNewsLetterPopup ? (
                <NewsletterPopup>
                    <button className="newsletterPopup__close_btn" onClick={closePopup}>{!message ? <>No Thanks</> : null} <img src={closeIcon} alt="Close" width="10" height="10" /></button>
                    <div className="newsletterPopup__content">
                        {message ? (
                            <div className="newsletterPopup__content__message">
                                <h2>{message}</h2>
                            </div>
                        ) : (
                            <>
                                <h2>Stay in the Loop!</h2>
                                <p>Stay up-to-date with new product launches and other exciting news from James Mudge Furniture Studio.</p>
                                <div className="newsletterPopup__content__form">
                                    <StyledForm onSubmit={handleSubmit}>
                                        <input type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.value)} />
                                        <button type="submit">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="31.733" height="13.333" viewBox="1549.267 7792.333 31.733 13.333">
                                                <g data-name="Group 127">
                                                    <path d="M1574.343 7804.657 1580 7799l-5.657-5.657" strokeLinejoin="round" strokeLinecap="round" stroke="#343434" fill="transparent" data-name="Path 18"/><path d="M1580 7799h-30" strokeLinejoin="round" strokeLinecap="round" stroke="#343434" fill="transparent" data-name="Line 42"/>
                                                </g>
                                            </svg>
                                        </button>
                                    </StyledForm>
                                </div>  
                            </>
                        )}
                    </div>
                </NewsletterPopup>
            ) : null}
        </div>
    )
}


export default withTheme(Homepage);
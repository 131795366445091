import styled , {keyframes} from 'styled-components';
import { Link } from 'react-router-dom';

const appearAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`

const disappearAnimation = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-10%);
    }
`

export const ItemContainer = styled(Link)`
    width: 100%;
    display: block;
    text-decoration: none;
    outline: none;
    font-family: ${props => props.theme.fonts.secondary};
    letter-spacing: 0.045em;

    opacity: 0;
    transform: translateY(10%);
    animation: ${props => props.isVisible ? appearAnimation : disappearAnimation};
    animation-duration: 800ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: ${props => props.isVisible ? '800ms' : '0'};

    &:hover{
        img{
            transform: scale(1.05);
        }
    }
    span{
        display: block;
        width: 100%;
        margin-bottom: 20px;
        aspect-ratio: 4/5;
        overflow: hidden;
    }
    img{
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
        aspect-ratio: 4/5;
        display: block;
        cursor: pointer;
        transition: transform 800ms ease-out;
    }
    h2{
        margin: 0 0 4px;
        font-size: 16px;
        font-weight: 400;
        color: ${props => props.theme.colors.text};
    }
    p{
        margin: 0;
        font-size: 16px;
        color: ${props => props.theme.colors.textLightOpaque};
    }
`
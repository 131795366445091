import styled from 'styled-components';

export const ContentContainer = styled.div`
    margin: 90px auto 0;
    padding: 0 340px;
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopXLarge}){
        padding: 0 280px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopLarge}){
        padding: 0 208px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        padding: 0 144px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        padding: 0 96px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        margin: 60px auto 0;
        padding: 0 40px;
    }
`

export const ItemsContainer = styled.div`
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(160px, 366px));
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        grid-template-columns: repeat(1, 1fr);
        row-gap: 60px;
        margin-top: 60px;
    }
    column-gap: 64px;
    row-gap: 84px;
    margin-top: 90px;
    justify-content: space-between;
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        justify-content: center;
    }
`

export const Filters = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        // display: none;
    }
`

export const Filter = styled.p`
    cursor: pointer;
    margin: 0 0 0 40px;
    color: ${props => props.active ? '#513623' : '#bfbfc1'};
    transition: 300ms ease-in-out;
    position: relative;
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.68px;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        font-size: 10px;
        line-height: 12px;
    }
    &:hover{
        color: #846249;

    }
    &::before{
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #513623;
        max-width: ${props => props.active ? '100%' : '0'};
        transition: 300ms ease-in-out;
        overflow: hidden;
    }
`
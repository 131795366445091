
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const ButtonStyle = styled(Link)`
    padding: 24px 32px;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.colors.textLight};
    color: ${props => props.theme.colors.textLight};
    text-transform: uppercase;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 14px;
    letter-spacing: 0.120em;
    line-height: 22px;
    display: flex;
    align-items: center;
    column-gap: 32px;
    width: max-content;
    margin: 32px auto 0;
    outline: none;
    text-decoration: none;
    overflow: hidden;
    transition: 300ms ease-in-out;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        margin-top: 62px;
    }
    span{
        transition: 300ms ease-in-out;
    }
    svg{
        transition: 300ms ease-in-out;
        path{
            stroke: ${props => props.theme.colors.textLight};
            
        }
    }
    &:hover{
        background: ${props => props.theme.colors.textLight};
        color: #fff;
        span{
            transform: translateX(32px)
        }
        svg{
            transform: translateX(72px);
        }
    }
`

const ExternalButtonStyle = styled.a`
    padding: 24px 32px;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.colors.textLight};
    color: ${props => props.theme.colors.textLight};
    text-transform: uppercase;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 14px;
    letter-spacing: 0.120em;
    line-height: 22px;
    display: flex;
    align-items: center;
    column-gap: 32px;
    width: max-content;
    margin: 32px auto 0;
    outline: none;
    text-decoration: none;
    overflow: hidden;
    transition: 300ms ease-in-out;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        margin-top: 62px;
    }
    span{
        transition: 300ms ease-in-out;
    }
    svg{
        transition: 300ms ease-in-out;
        path{
            stroke: ${props => props.theme.colors.textLight};
            
        }
    }
    &:hover{
        background: ${props => props.theme.colors.textLight};
        color: #fff;
        span{
            transform: translateX(32px)
        }
        svg{
            transform: translateX(72px);
        }
    }
`

const CustomButton = ({text,to,external=false}) => {
    return(
        <>
            {external &&
                <ExternalButtonStyle href={to}>
                    <span>{text}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40.707" height="17.678" viewBox="0 0 40.707 17.678"><g transform="translate(-1025 -3771.676)"><path d="M0,0V12H12" transform="translate(1056.515 3789) rotate(-135)" fill="none" stroke="#343434" strokeWidth="1"/><line x1="40" transform="translate(1025 3780.569)" fill="none" stroke="#343434" strokeWidth="1"/></g></svg>
                </ExternalButtonStyle>
            }
            {!external &&
                <ButtonStyle to={to}>
                    <span>{text}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40.707" height="17.678" viewBox="0 0 40.707 17.678"><g transform="translate(-1025 -3771.676)"><path d="M0,0V12H12" transform="translate(1056.515 3789) rotate(-135)" fill="none" stroke="#343434" strokeWidth="1"/><line x1="40" transform="translate(1025 3780.569)" fill="none" stroke="#343434" strokeWidth="1"/></g></svg>
                </ButtonStyle>
            }
        </>
    )
}

export default CustomButton;
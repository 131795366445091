import seating from '../../assets/images/seating.webp'
import { ContentContainer, Filter, Filters, ItemsContainer } from './categoryStyles';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Purely for testing
// import { CollectionsTest } from "../../testData";
import { withTheme } from "styled-components";

import { PageContentWrapper } from '../../hoc'
import { CollectionItem, Hero, Footer } from '../../components'
import Loading from '../../components/loading/loading'

const CategoryPage = (props) => {
    const slug = 'collections';
    const [data, setData] = useState(null);
    const [category, setCategory] = useState(null);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data));

        fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/collections`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setCategory(data));
    }, []);

    if(!data) return <Loading />;

    return (
        <div>
            <Helmet>
                <title>{data.title} | James Mudge</title>
                <meta
                    name="description"
                    content="Browse James Mudge Furniture's collections of hand-made, solid wood furniture."
                />
            </Helmet>
            <Hero 
                isCompact={true} 
                centerText 
                text={data.title} 
                image={data.metadata.image.url} 
            />
            <div>
                <ContentContainer theme={props.theme}>
                    <ItemsContainer >
                        {!category &&
                            <Loading />
                        }
                        {category &&
                            <>
                                {category.terms.map((term, i) => {
                                    return (
                                        <CollectionItem
                                            slug={term.slug}
                                            title={term.name}
                                            image={term.metadata.listing_image.sizes.large}
                                            url={`/products/collections/${term.slug}`}
                                            key={i}
                                            isVisible={isVisible}
                                        />
                                    )
                                })}
                            </>
                        }
                    </ItemsContainer>
                </ContentContainer>
            </div>
        </div>
    );
}

export default withTheme(CategoryPage);
import { withTheme } from 'styled-components';
import { ItemContainer } from './collectionItemStyles'


const CollectionItem = (props) => {

    return (
        <ItemContainer to={props.url} isVisible={props.isVisible}>
            <span>
                <img src={props.image} alt={props.title} />
            </span>
            <h2>{props.title}</h2>
            {props.price &&
                <p>From R {props.price}</p>
            }
        </ItemContainer>
    )
}

export default withTheme(CollectionItem);
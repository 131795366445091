import styled from 'styled-components'



export const TextWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 72px;
    margin-bottom: 196px;
    // margin-top: 192px;
    div{
        &:first-of-type{
            p{
                font-family: ${props => props.theme.fonts.primary};
                font-size: 42px;
                letter-spacing: 0.005em;
                line-height: 60px;
                color: ${props => props.theme.colors.text};
            }
        }
        &:last-of-type{
            p{
                font-family: ${props => props.theme.fonts.secondary};
                font-size: 18px;
                line-height: 30px;
                letter-spacing: 0.72px;
                color: ${props => props.theme.colors.text};
            }
        }
        p{
            width: 100%;
            font-weight: 400;
            letter-spacing: 0.72px;
            margin: 0;
            
            br{
                margin-top: 24px;
                display: block;
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 32px;
        margin: 0 0 72px;
        div{
            p{
                &:first-of-type{
                    font-size: 32px;
                    line-height: 48px;
                
                }
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        div{
            p{
                &:first-of-type{
                    font-size: 32px;
                    line-height: 48px;
                }
            }
            &:last-of-type{
                p{
                    font-size: 16px;
                    line-height: 30px;
                }
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        div{
            p{
                &:first-of-type{
                    font-size: 32px;
                    line-height: 48px;
                }
            }
            &:last-of-type{
                p{
                    font-size: 16px;
                    line-height: 30px;
                }
            }
        }
    }
`

export const GridRow = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 72px;
    position: relative;
    grid-row-gap: 72px;
    &>img{
        width: 100%;
        &:first-of-type{
            grid-area: 1/1/1/4;
            
        }
        &:nth-of-type(2){
            grid-area: 1/3/1/5;
            position: absolute;
            top: 15%;
        }
    }
    img{
        width: 100%;
    }
    &>div{
        position: relative;
        z-index: 20;
        &:first-of-type{
            grid-area:  1/1/1/3;
        }
        &:nth-of-type(2){
            grid-area:  1/3/1/5;
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopLarge}){
        grid-column-gap: 40px;
    }
    @media (max-width: ${props => props.theme.breakpoints.desktop}){
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0;
        &>img{
            &:first-of-type{
                grid-area: 1/1/1/3;
            }
            &:nth-of-type(2){
                grid-area: 1/2/1/3;
           
            }
        }
        &>div{
            width: 100%;
            &:first-of-type{
                grid-area: 1/1/1/3;
            }
            &:nth-of-type(2){
                grid-area: 2/1/2/3;
            }
        }
    }
    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        &>img{
            &:nth-of-type(2){
                display: none;
            }
        }
    }
`

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 240px;
    h3{
        font-family: ${props => props.theme.fonts.primary};
        font-size: 36px;
        line-height: 43px;
        font-weight: 400;
        color: ${props => props.theme.colors.text};
        margin: 0 0 56px;
    }
    p{
        margin-bottom: 24px;
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.72px;
        width: 100%;
        max-width: 604px;
      
    }
    ${GridRow}{
        &:last-of-type{
            &>img{
                grid-area: 2/2/2/5;
                margin-top: -30%;
            }
        }
        &:nth-of-type(2){
            &>div:last-of-type{
                align-self: flex-end;
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopLarge}){
        h3{
            margin: 0 0 32px;
        }
        ${GridRow}{
            &:last-of-type{
                &>img{
                    margin-top: -15%;
                }
            }
        }
    }
    @media (max-width: ${props => props.theme.breakpoints.desktop}){
        row-gap: 96px;
        p{
            max-width: unset;
        }
        ${GridRow}{
            &:last-of-type{
                img{
                    width: 100%;
                }
                &>img{
                    display: none;
                }
                &>div:nth-of-type(2){
                    grid-area: 2/1/2/3;
                    margin-top: 48px;
                }
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        p {
            font-size: 16px;
            line-height: 30px;
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        p {
            font-size: 16px;
            line-height: 30px;
        }
        ${GridRow}{
            &:last-of-type{
                &>div:nth-of-type(2){
                    margin-top: 0;
                }
            }
        }
    }
`





export const Quote = styled.div`
  
    p{
        font-family: ${props => props.theme.fonts.primary};
        font-size: 58px;
        line-height: 76px;
        font-weight: 300;
        color: ${props => props.theme.colors.text};
        letter-spacing: -0.02em;
        margin: 0;
        position: relative;
        &::before{
            content: '';
            display: block;
            width: 2px;
            height: 90%;
            background: #000;
            position: absolute;
            left: -32px;
            top: 50%;
            transform: translateY(-50%);

        }
    }
    @media (max-width: ${props => props.theme.breakpoints.desktop}){
        margin-top: 40px;
        p{
            font-size: 48px;
            line-height: 60px;
        }
    }
    @media (max-width: ${props => props.theme.breakpoints.mobile}){
        margin-top: 0;
        p{
            font-size: 32px;
            line-height: 38px;
            &::before{
                left: -24px;
            }
        }
    }
`
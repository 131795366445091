import { useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components'
import { Helmet } from "react-helmet";

import { Footer } from '../../components'
import { PageContentWrapper } from '../../hoc'
import Loading from '../../components/loading/loading'

const TermsWrapper = styled.div`
    margin: 148px auto 0;
    &>div{
        padding-top: 128px;
        // margin-left: 180px;
        display: flex;
        justify-content: space-between;
        column-gap: 168px;
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
            padding-top: 0;
        }
        .terms{
            
            h2, p{
                font-family: ${props => props.theme.fonts.secondary};
                font-size: 16px !important;
                font-weight: 400 !important;
                line-height: 30px;
                letter-spacing: 0.72px;
                margin-bottom: 24px;
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        margin: 128px auto 0;
        &>div{
            margin-left: 128px;
            column-gap: 72px;
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        margin: 56px auto 0;
        &>div{
            margin-left: 0;
            column-gap: 0;
            row-gap: 56px;
            flex-direction: column;
            h1{
                text-align: center;
                font-weight: 400;
                margin: 0;
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        margin: 32px auto 0;
        &>div{
            row-gap: 32px;
        }
    }
`


const Terms = ({theme}) => {
    const slug = 'terms-and-conditions';
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data));
    }, []);

    if(!data) return <Loading />;

    return(
        <div>
            <Helmet>
                <title>{data.title} | James Mudge</title>
                <meta
                    name="description"
                    content="James Mudge Furniture's terms and conditions."
                />
            </Helmet>
            <PageContentWrapper>
                <TermsWrapper theme={theme}>
                <div>
                    <h1>{data.title}</h1>
                    <div className='terms' dangerouslySetInnerHTML={{ __html: data.content }}></div>
                </div>
                </TermsWrapper>

            </PageContentWrapper>
        </div>
        
    )
}

export default withTheme(Terms);
import { Fade } from "react-reveal";

import styled, { withTheme } from "styled-components";

const SectionContainer = styled.div`
    padding: 0 0 90px;
    box-sizing: border-box;
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        padding: 0 0 172px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        padding: 0 0 144px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        padding: 0 0 96px;
    }
`

const SectionWrapper = ({children, id , revealFraction=0.1, theme, delay=0}) => {
    return(
        <>
        {/* <Fade bottom delay={delay} duration={1500} fraction={revealFraction}> */}
            <SectionContainer id={id} theme={theme}>
                {children}
            </SectionContainer>
            
        {/* </Fade> */}
        </>
    )
}

export default withTheme(SectionWrapper);

import { useState } from 'react';
import { withTheme } from 'styled-components';
import { PageContentWrapper } from '../../hoc';
import FooterColumn from '../footerColumn/footerColumn';
import { FooterContainer, StyledLink, FooterContent, ContactGroup, StyledForm, Scroller } from './footerStyles';

const Footer = (props) => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        let email = e.target.email.value;
        if(email){
            fetch(`${process.env.REACT_APP_REST_API_URL}/add-email-to-mailing-list?email=${email}`, {
                method: 'GET',
                mode: 'cors'
            }).then(response => response.json())
                .then(data => {
                    if(data.success) {
                        setEmail("");
                        setMessage('Thank you for subscribing!');
                    } else {
                        setMessage('Something went wrong. Please try again.');
                    }
                });
        }
    }

    return(
        <FooterContainer theme={props.theme}>  
            {
                props.scroller ?
                <Scroller 
                    onClick={() => {
                        window.scrollTo(0,0,{
                            behavior: 'smooth'
                        })
                    }}
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24.633" 
                    height="25.733" 
                    viewBox="267.5 5761.5 24.633 25.733"
                >
                    <g data-name="Group 119"><path d="M279.814 5762.5v24" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Line 20"/><path d="m268.5 5773.814 11.314-11.314 11.314 11.314" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Path 12"/></g>
                </Scroller>
                :
                null
            }
            <PageContentWrapper>
                <FooterContent theme={props.theme}>
                {props.collections &&
                    <FooterColumn title={"Collections"} isHiddenMobile={true}>
                        {props.collections.terms.map((collection, index) => {
                            return (
                                <StyledLink key={index} to={`/products/collections/${collection.slug}`}>{collection.name}</StyledLink>
                            );
                        })}
                    </FooterColumn>
                }

                {props.rooms &&
                    <FooterColumn title={"Rooms"} isHiddenMobile={true}>
                        {props.rooms.terms.map((room, index) => {
                            return (
                                <StyledLink key={index} to={`/products/rooms/${room.slug}`}>{room.name}</StyledLink>
                            );
                        })}
                    </FooterColumn> 
                }

                <FooterColumn title={"Showroom"}>
                    <ContactGroup>
                        <div>
                            <p>1 Calcutta Street</p>
                            <p>Paarden Eiland</p>
                            <p>Cape Town</p>
                        </div>
                        <div>
                            <p>Monday - Friday</p>
                            <p>9am - 4pm</p>
                            <p>By appointment only</p>
                        </div>
                    </ContactGroup>
                </FooterColumn> 

                <FooterColumn title={"Contact"}>
                    <ContactGroup>
                        <div>
                            <p>T. +27 (0)79 448 1683</p>
                            <p>E. <a href="mailto:info@jamesmudge.com">info@jamesmudge.com</a></p>
                        </div>
                        <div>
                            <p>Keep up to date on new, products<br />launches  and inspiration</p>
                        </div>
                        <div>
                            <StyledForm onSubmit={handleSubmit}>
                                <input type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.value)} />
                                <button type="submit">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="31.733" height="13.333" viewBox="1549.267 7792.333 31.733 13.333">
                                        <g data-name="Group 127">
                                            <path d="M1574.343 7804.657 1580 7799l-5.657-5.657" strokeLinejoin="round" strokeLinecap="round" stroke="#343434" fill="transparent" data-name="Path 18"/><path d="M1580 7799h-30" strokeLinejoin="round" strokeLinecap="round" stroke="#343434" fill="transparent" data-name="Line 42"/>
                                        </g>
                                    </svg>
                                </button>
                            </StyledForm>
                            {message && <p>{message}</p>}
                        </div>
                    </ContactGroup>
                </FooterColumn> 

                </FooterContent> 
            </PageContentWrapper>
        
         
        </FooterContainer>
    )
}


export default withTheme(Footer);
import styled from "styled-components";

export const FollowSection = styled.section`
    padding: 128px 280px 0;
    border-top: 2px solid #d8d8d8;
    @media (max-width: ${props => props.theme.breakpoints.desktopXLarge}){
        padding: 128px 208px 0;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopLarge}){
        padding: 128px 192px 0;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        padding: 128px 144px 0;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        padding: 128px 96px 0;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        padding: 88px 40px 0;
    }
    h1{
        text-align: center;
        margin: 0 0 88px;
        color: ${props => props.theme.colors.menuBG };
        font-size: 36px;
        font-weight: 400;
    }
    &>div{
        display: grid;

        margin: 0 auto;
        // grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 56px;
        @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
            grid-template-columns: repeat(2,1fr);
            grid-gap: 32px;
        }
    }
`

export const GridRow = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 72px;
    position: relative;
    color: ${props => props.theme.colors.text};
    &>div{
        img{
            width: 100%;
        }
        &:first-of-type{
            grid-area: 1/1/1/3;
        }
        &:nth-of-type(2){
            grid-area: 1/3/1/5;
            h3{
                margin: 0 0 40px;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.120em ;
                font-family: ${props => props.theme.fonts.secondary};
                font-weight: 400;
                text-transform: uppercase;
            }
            p{
                font-family: ${props => props.theme.fonts.primary};
                font-size: 58px;
                line-height: 76px;
                letter-spacing: 0.005em;
                margin: 0;
                max-width: 505px;
                width: 100%;
            }
        }
        &:last-of-type{
            grid-area: 2/2/2/5;
            margin-top: -30%;
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopLarge}){
        &>div{
            &:nth-of-type(2){
                p{
                    font-size: 42px;
                    line-height: 60px;
                }
            }
           
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        &>div{
            &:nth-of-type(2){
                h3{
                    margin: 0 0 24px;
                }
            }
            &:last-of-type{
                margin-top: -15%;
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        &>div{
            &:nth-of-type(2){
                p{
                    font-size: 34px;
                    line-height: 50px;

                }
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        column-gap: 32px;
        &>div{
            &:last-of-type{

            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.xsmall}){
        &>div{
            &:first-of-type{
                grid-area: 2/1/2/4;
            }
            &:nth-of-type(2){
                grid-area: 1/1/1/5;
                text-align: center;
                p{
                    margin: 0 auto 48px;
                }
            }
            &:last-of-type{
                grid-area: 3/2/3/5;
                margin-top: -30%;
            }
        }
    }
`

export const CollectionsWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    h1{
        font-size: 32px;
        margin: 0 0 96px;
        color: ${props => props.theme.colors.text};
        font-family: ${props => props.theme.fonts.primary};
        font-weight: 400;
        line-height: 36px;
    }
    &>div{
        &:first-of-type{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 96px;
            margin-bottom: 148px;
            @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
                margin-bottom: 36px;
            }
            @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
                margin-bottom: 36px;
            }
            &>div{
                &:first-of-type{
                    span {
                        aspect-ratio: 1 / 1;
                    }

                    img{
                        aspect-ratio: 1;
                        // max-height: 505px;
                    }
                }
                &:last-of-type{
                    height: 100%;
                    &>div{
                        height: 100%;

                        span {

                        }
                        img{
                            aspect-ratio: unset;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
    &>a{
        margin-top: 112px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        h1{
            font-size: 22px;
            line-height: 36px;
            margin: 0 0 36px;
        }
        &>div{
            &:first-of-type{
                grid-column-gap: 36px;
                &>div{
                    &:last-of-type{
                    height: unset;
                    &>div{
                        img{
                            height: unset;
                        }
                    }
                }
                }
            }
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.xsmall}){
        &>div{
            &:first-of-type{
                grid-template-columns: 1fr;
                grid-row-gap: 36px;
            }
        }
    }
`

export const CollectionGroup = styled.div`
    width: 100%;

    a {
        text-decoration: none;

        &:hover {

            img {
                transform: scale(1.05);
            }
        }
    }

    span {
        width: 100%;
        display: block;
        overflow: hidden;
    }

    h2{
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 16px;
        letter-spacing: 0.045em;
        line-height: 30px ;
        color: ${props => props.theme.colors.textLight};
        margin: 32px 0 0;
        font-weight: 400;
    }
    img{
        width: 100%;
        height: auto;
        // max-height: 820px;
        aspect-ratio: 31/21;
        object-fit: cover;
        transition: transform 800ms ease-out;
        display: block;

    }
`    
export const NewsletterPopup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999999;
    width: 520px;
    min-height: 380px;
    padding: 20px;
    background: #fff;
    box-sizing: border-box;
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        width: 90%;
    }

    .newsletterPopup__close_btn {
        display: flex;
        align-items: center;
        font-family: ${props => props.theme.fonts.secondary};
        text-transform: uppercase;
        padding: 0;
        border: none;
        background: transparent;
        cursor: pointer;

        img {
            display: inline-block;
            margin: 0 0 0 6px;
        }
    }

    .newsletterPopup__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        margin: 0 0 40px;

        h2 {
            font-size: 32px;
            line-height: 36px;
            margin: 50px 0 20px; 
            text-align: center;
        }
    
        p {
            font-size: 16px;
            line-height: 28px;
            font-family: ${props => props.theme.fonts.secondary};
            width: 265px;
            margin: 0 0 54px;
            text-align: center;
        }

        .newsletterPopup__content__form {
            width: 300px;
        }

        .newsletterPopup__content__message {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
`
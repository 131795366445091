import styled from 'styled-components'
import { Link } from 'react-router-dom'

const FollowItemBackground = styled.div`
    background-image: url(${props => props.image});
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    filter: grayscale(1);
    transition: 600ms ease-in-out !important;
`

const Blur = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: rgba(0,0,0,0.6);
    opacity: 0;
`

const FollowItemContainer = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    overflow: hidden;
    position: relative;
    &>*{
        transition: 300ms ease-in-out;
    }
    &:hover{
        ${Blur}, p{
            opacity: 1;
        }
        p{
            transform: translateY(0px);
        }
        ${FollowItemBackground}{
            transform: scale(1.05);
            transition: transform 800ms ease-out !important;
        }
    }
    p{
        position: relative;
        z-index: 5;
        color: #fff;
        opacity: 0;
        font-size: 30px;
        font-weight: 600;
        transform: translateY(24px);
        outline: none;
        text-decoration: none;
    }
`




const FollowItem = ({image,text,to}) => {
    return(
        <FollowItemContainer href={to} target="_blank">
            <FollowItemBackground image={image}>

            </FollowItemBackground>
            <Blur />
        </FollowItemContainer>
    )
}

export default FollowItem;
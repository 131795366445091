import { withTheme } from "styled-components";
import { Carousel, CarouselItem, CarouselItems, Content, ExitToggle, PopupContainer } from "./popupStyles";


import { useEffect, useState } from "react";
import {RenderIf} from "../../hoc";
import {GalleryArrow} from "../index";


const GalleryCarousel = ({data, isImage, show}) => {
    const [galleryPosition, setGalleryPosition] = useState(data.positionPreset);
    
    useEffect(() => {
        setGalleryPosition(data.positionPreset);
    }, [data.positionPreset])

    useEffect(() => {
        let x = null;
        if(show === false){
            x = setTimeout(() => {
                setGalleryPosition(0);
            }, 500);
        }
        else{
            setGalleryPosition(data.positionPreset);
        }
        
        return () => clearTimeout(x);
    }, [show, data.positionPreset])
    return(
        <Content type={isImage} show={show}>
            <div>
                
                {/* <RenderIf condition={data.carouselData.length > 1}> */}
                    <GalleryArrow position={'left'} show={(galleryPosition > 0)} action={() => galleryPosition > 0 ? setGalleryPosition(galleryPosition-1) : null} />
                {/* </RenderIf> */}
               
                
                {/* <RenderIf condition={data.carouselData.length > 1}> */}
                    <GalleryArrow position={'right'} show={(galleryPosition < data.carouselData.length - 1)} action={() => galleryPosition < data.carouselData.length - 1 ? setGalleryPosition(galleryPosition+1) : null} />
                {/* </RenderIf> */}
               
                <Carousel>
                    <img src={data && data.carouselData.length ? data.carouselData[0].url : ''} alt={data && data.carouselData.length ? data.carouselData[0].alt : ''} caption={data && data.carouselData.length ? data.carouselData[0].caption : ''} />
                    <CarouselItems>
                        {
                            data.carouselData.map((image,index) => {
                                return <CarouselItem active={galleryPosition === index} key={index} src={image.url} alt={image.alt} caption={image.caption} />
                            })
                        }
                        
                    </CarouselItems>
                </Carousel>
            
            </div>
            <p>{
                isImage === "image"
                ?
                data.name
                :
                data.carouselData[galleryPosition].name
            }</p>
        </Content>
    )
}

const Popup = ({theme,show,action,isImage,data}) => {

    
    return(

        <PopupContainer show={show} theme={theme}>
        
            <ExitToggle xmlns="http://www.w3.org/2000/svg" width="11.917" height="11.917" viewBox="1765.383 61.333 11.917 11.917" show={show} onClick={() => action(false)}>
                <g data-name="Group 33">
                    <path d="m1776.298 62.343-9.9 9.9" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Line 16"/>
                    <path d="m1776.05 71.995-9.404-9.404" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Line 17"/>
                </g>
            </ExitToggle>
            <GalleryCarousel data={data} show={show} isImage={isImage} />
       
        </PopupContainer>
    )
}


export default withTheme(Popup);
import Fade from 'react-reveal/Fade';

const AnimationWrapper = ({ children, revealFraction=0.2, delay=300 }) => {
    return(
        <Fade bottom delay={delay} duration={1000} fraction={revealFraction} distance={'10%'}>
            {children}
        </Fade>
    )
}

export default AnimationWrapper;
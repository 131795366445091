import chairs from '../../assets/images/chairs.webp';
import { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import { Finish, FinishStyles, Gallery, PageContainer, ProductDetails, ProductInfo, Spinner } from "./productStyles";
import { CollectionsTest, WoodTypes } from "../../testData";
import { useParams } from "react-router";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ProductGallery from '../../components/productGallery/productGallery'

import { Hero, Popup, CustomButton, Footer } from '../../components'
import { PageContentWrapper, AnimationWrapper } from '../../hoc';
import Loading from '../../components/loading/loading'

const ProductPage = ({theme}) => {
    const [activeFinish, setActiveFinish] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState({
        type: "image",
        name: "",
        carouselData: [],
        positionPreset: 0
    });
    // const [loadedData, setLoadedData] = useState(false);
    const [data, setData] = useState(null);
    const [finishes, setFinishes] = useState(null);
    const params = useParams();

    useEffect(() => {
        // CollectionsTest.map((item) => {
        //     if(item.id === params.id && !loadedData) {
        //         setLoadedData(item);
             
        //     }
        //     return '' 
        // })
        // return () => {

        // }
        fetch(`${process.env.REACT_APP_REST_API_URL}/post-type/products/${params.id}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data));

        fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/finishes/products/${params.id}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setFinishes(data));
    }, [])

    const handlePopup = (data) => {
        setPopupData(data);
        setShowPopup(true);
    }

    if(!data) return <Loading />;

    return(
        <div>
            <Helmet>
                <title>{data.title} | James Mudge</title>
                <meta
                    name="description"
                    content={data.content}
                />
            </Helmet>
            {/*{
                loadedData
            ? */}
                <>
                    <Hero 
                        isCompact={false} 
                        image={data.metadata.hero_image.url}
                        tablet_image={data.metadata.hero_image_tablet ? data.metadata.hero_image_tablet.sizes['2048x2048'] : data.metadata.hero_image.sizes['2048x2048']} 
                        mobile_image={data.metadata.hero_image_mobile ? data.metadata.hero_image_mobile.sizes['2048x2048'] : data.metadata.hero_image.sizes['2048x2048']} 
                        type='scroll' 
                        linkTarget='product' 
                    />
                    <div>
                        <PageContentWrapper id="product">
                            <PageContainer>
                                <ProductInfo theme={theme}>
                                    <AnimationWrapper>
                                        <h1>{data.title}</h1>
                                        <p>{data.content}</p>
                                    </AnimationWrapper>
                                    <AnimationWrapper>
                                        <div>
                                            <img src={data.featured_image} alt='product' />
                                            <ProductDetails>
                                                {data.metadata.information.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <h2>{item.title}</h2>
                                                            <div>
                                                                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {data.metadata.downloads.length &&
                                                    <>
                                                        <h2>Downloads</h2>
                                                        <div>
                                                            {data.metadata.downloads.map((download, index) => <a key={index} href={download.file.url} target="_blank">{download.title}</a>)}
                                                        </div>
                                                    </>
                                                }
                                                <h2>Finishes</h2>
                                                <FinishStyles theme={theme}>
                                                    {finishes &&
                                                        <>
                                                            {finishes.map((item, i) => {
                                                                return (
                                                                    <Finish 
                                                                        active={i === activeFinish} 
                                                                        key={i} 
                                                                        onClick={() => {
                                                                            setActiveFinish(i);
                                                                            handlePopup({
                                                                                type: "finish",
                                                                                name: finishes[i].caption,
                                                                                carouselData: finishes.map((finish) => {
                                                                                    return {url: finish.metadata.image.sizes.large, alt: finish.metadata.image.alt, name: finish.metadata.image.caption}
                                                                                }),
                                                                                positionPreset: i
                                                                            });
                                                                        }}
                                                                    >
                                                                        <img src={item.metadata.image.sizes.medium} alt={item.metadata.image.alt} />
                                                                    </Finish>
                                                                )
                                                            })}
                                                        </>
                                                    }
                                                </FinishStyles>
                                                {/* <CustomButton text={'Enquire Now'} external={true} to={`mailto:info@jamesmudge.com?subject=${data.title} - Query`} /> */}
                                            </ProductDetails>
                                        </div>
                                    </AnimationWrapper>
                                </ProductInfo>
                         
                            </PageContainer>
                        </PageContentWrapper>
                        <AnimationWrapper>
                            {/* <Gallery> */}
                            <div>
                                {data.metadata.gallery.length ? (
                                    <ProductGallery 
                                        items={[...data.metadata.gallery.map((image) => {
                                            return {url: image.url, alt: image.title, caption: image.caption};
                                        })]} 
                                    />
                                ) : null}
                                {/*
                                <img 
                                    src={data.metadata.gallery[0].url} 
                                    onClick={() => {
                                        handlePopup({
                                            type: "image",
                                            name: data.metadata.gallery[0].caption,
                                            carouselData: [...data.metadata.gallery.map((image) => {
                                                return {url: image.url, alt: image.alt, name: image.caption};
                                            })],
                                            positionPreset: 0

                                        })
                                    }} 
                                    alt={data.metadata.gallery[0].alt}
                                />
	    			            */}
                                <CustomButton text={"Enquire Now"} external={true} to={`mailto:info@jamesmudge.com?subject=${data.title} - Query`} />
                            {/* </Gallery> */}
                            </div>
                        </AnimationWrapper>
                    </div>
                                     
                    <Popup show={showPopup} action={setShowPopup} isImage={popupData?.type} data={popupData} />
                </>
                {/*:
                <Spinner />
                */}
            {/*}*/}
           
        </div>
    )
}

export default withTheme(ProductPage);

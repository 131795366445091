import { withTheme } from "styled-components";
import { InfoTextContainer } from "./infoTextStyles";

const InfoText = ({children, id,theme}) => {
    return (
        <InfoTextContainer id={id} theme={theme}>
            {children}
        </InfoTextContainer>
    );
}

export default withTheme(InfoText);
import styled from 'styled-components'

export const InfoTextContainer = styled.div`
    width: 80%;
    min-width: 640px;
    max-width: 1118px;
   
    margin: 0 auto;
    font-size: 38px ;
    text-align: center;
    line-height: 52px;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        min-width: unset;
        width: 100%;
        font-size: 24px;
        letter-spacing: 0.005em;
        line-height: 38px;
        width: 100%;
    }

`
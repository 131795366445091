import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom';

import galleryHoverIcon from '../../assets/images/gallery-hover-icon.svg';

export const PageContainer = styled.div`
    width: 100%;
    // margin: 172px auto 0;
    margin: 0 auto 0;
`

export const ProductInfo = styled.div`
    text-align: center;
    margin-bottom: 128px;
    font-family: ${props => props.theme.fonts.primary};
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        margin-bottom: 64px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        margin-bottom: 64px;
    }
    &>h1{
        font-size: 38px;
        font-weight: 400;
        margin: 0 0 48px;
        color: ${props => props.theme.colors.text};

    }
    &>p{
        font-size: 16px;
        color: ${props => props.theme.colors.textLight};
        margin: 0 auto;
        width: 80%;
        max-width: 1050px;
        font-family: ${props => props.theme.fonts.secondary};
        letter-spacing: 0.045em ;
        line-height: 30px;
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
            width: 90%;
        }
        
    }
    &>div{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 104px;
        margin-top: 136px;
        &>img{
            width: 100%;
            height: auto;
            aspect-ratio: 4/5;
        }
        @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
            grid-template-columns: 1fr;
            margin-top: 68px;
            grid-gap: 52px;
        }
        @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
            margin-top: 68px;
            grid-gap: 52px;
        }
    }

`

export const ProductDetails = styled.div`
    width: 100%;
    text-align: start;
    color: ${props => props.theme.colors.textLight};
    font-family: ${props => props.theme.fonts.secondary};
    display: flex;
    flex-direction: column;
    h2{
        
        letter-spacing: 0.12em ;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 22px;
    }
    p{
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.045em ;
        margin: 0 0 4px;
    }
    &>div{
        margin-bottom: 28px;
    }
    div>a{
        font-size: 16px;
        color: ${props => props.theme.colors.textLight};
        line-height: 30px;
        letter-spacing: 0.045em ;
        margin: 0 0 4px;
        display: block;
    }
    &>a{
        margin: auto 0 0;
    }
    
`

export const Gallery = styled.div`
    padding: 0 280px;
    width: 100%;
    box-sizing: border-box;
    h2{
        font-size: 32px;
        margin-bottom: 64px;
        color: ${props => props.theme.colors.text};
        font-weight: 400;
        
    }
    img{
        cursor: pointer;
        width: 100%;
        height: auto;
        max-height: 820px;
        object-fit: cover;
        object-position: center;
        display: block;
        pointer-events: all ;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopLarge}){
        padding: 0 192px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        padding: 0 144px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        padding:0;
        h2{
            text-align: center ;
        }
    }
   
`

const animateSpinner = keyframes`
    0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
`

const secondAnim = keyframes`
 0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`

    color: #000;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 320px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: ${animateSpinner} 1.7s infinite ease, ${secondAnim} 1.7s infinite ease;
    animation: ${animateSpinner} 1.7s infinite ease, ${secondAnim} 1.7s infinite ease;
`


export const FinishStyles = styled.div`
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(64px, 120px));
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        // grid-template-columns: repeat(auto-fit, minmax(32px, 56px))
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
   
`

export const Finish = styled.div`
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    cursor: pointer;
    position: relative;
    &::before{
        content: '';
        font-family: 'Roboto';
        font-size: 40px;
        font-weight: 400;
        // background: rgba(255,255,255,0.4);
        background: url(${galleryHoverIcon}) no-repeat center center;
        background-size: cover;
        color: #000;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 200;
        transition: 300ms ease-in-out;
        // opacity: ${props => props.active ? 1 : 0};
        opacity: 0;
        border: ${props => props.hasBorder ? '1px solid #000' : 'none'};
        box-sizing: border-box;
       
    }
    &:hover{
        &::before{
            // background: ${props => props.hasBorder ? '#000' : 'rgba(255,255,255,0.4)'};
            // color: ${props => props.hasBorder ? '#fff' : '#000'};
            opacity: 1;
        }
    }
    img{
        width: 100%;
        height: 100%;
        display: block;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        &::before{
            font-size: 24px;
        }
    }
`

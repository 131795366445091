import styled from 'styled-components';
import { Link } from 'react-router-dom';


export const FooterContainer = styled.footer`
    padding: 0 0 172px;
    box-sizing: border-box;
    background: ${props => props.theme.colors.footerBG};
    color: ${props => props.theme.colors.textLight};
    position: relative;
    margin-top: 92px;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        margin-top: 92px;
        padding-top: 0;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        margin-top: 92px;
        padding-top: 0;
    }
`

export const FooterContent = styled.div`
    display: flex;
    width: 100%;
    margin: 0 auto;
    column-gap: 96px ;
    &>div{
        &:last-of-type{
            margin-left: auto;
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        column-gap: 48px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        flex-direction: column;
        column-gap: 0;
        row-gap: 48px;
        &>div{
            width: 100%;
            max-width: 400px;
            &:last-of-type{
                margin-left: 0;
             
            }
        }
    }
`

export const StyledLink = styled(Link)`
    color: ${props => props.theme.colors.textLight};
    text-decoration: none;
    outline: none;
    font-family: ${props => props.theme.fonts.primary};
    display: block;
    margin:  0 0 8px;
    font-size: 20px;
`

export const ContactGroup = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    p{
        font-family: ${props => props.theme.fonts.primary};
        margin:  0 0 6px;
        font-size: 20px;
        a {
            color: ${props => props.theme.colors.textLight};
            text-decoration: none;
        }
    }
`

export const StyledForm = styled.form`
    display: flex;
    padding-bottom: 6px ;
    border-bottom: 1px solid ${props => props.theme.colors.textLight};
    width: 100%;
    margin: 0 0 16px;
    column-gap: 32px;
    input{
        flex-grow: 2;
        border: none;
        outline: none;
        background: none;
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 12px;
        letter-spacing: 0.63px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #f7f7f6 inset !important;
        }
    }
    button {
        padding: 0;
        border: none;
        background: none;
        svg{
            transition: 300ms ease-in-out;
            cursor: pointer;
            stroke: ${props => props.theme.colors.textLight};
            &:hover{
                transform: translateX(4px);
            }
        }
    }
`

export const Scroller = styled.svg`
    padding: 8px;
    background: ${props => props.theme.colors.textLight};
    cursor: pointer;
    position: absolute;
    top: -21px;
    right: 10%;
    border-radius: 50%;

`
import styled from 'styled-components'

export const PopupContainer = styled.div`
    position: fixed;
    top: 0;
    right: ${props => props.show ? '0' : '-200vw'};
    width: 100vw;
    height: 100vh;
    background: #221f20;
    z-index: ${props => props.show ? "15000" : "-2"};
    transition: 800ms ease-in-out;
    opacity: ${props => props.show ? "1" : "0"};
    transition-delay: ${props => props.show ? "0" : "800ms"};
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        top: -2px;
        height: calc( 100vh + 2px);
    }
`

export const GalleryImage = styled.img`
    
`

export const Carousel = styled.div`
    position: relative;
    &>img{
        opacity: 0 !important;
    }
`

export const CarouselItems = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`

export const CarouselItem = styled.img`
    position: absolute;
    top: 0;
    left: 0 !important;
    transform-origin: center ;
    // transform: ${props => props.active ? 'scale(1)' : 'scale(0) translateX(-100%) rotate(90deg)'} !important;
    transform: ${props => props.active ? '' : 'translateX(-100%)'} !important;
    opacity: ${props => props.active ? '1' : '0'} !important;
    width: 100%;
    height: 100%;
    transition: 800ms ease-in-out;
    transition-delay: 0 !important;
    user-select: none;
    object-fit: contain;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: ${props => props.type === "image" ? '0' : '40px'};
    &>div{
        position: relative;
        z-index: 150001;
     
    }
    p{
        font-family: ${props => props.theme.fonts.secondary};
        margin: 0;
        color: #fff;
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        letter-spacing: 0.72px;
        margin-top: ${props => props.type === "image"  ? "24px" : "72px"};
        text-align: center;
        transition: 400ms ease-in-out;
        // transform: ${props => props.show ? "translateY(0px)" : "translateY(32px)"};
        opacity: ${props => props.show ? "1" : "0"};
        transition-delay: ${props => props.show ? "1200ms" : "200ms"};
    }
    &>div>div>img{
        height: ${props => props.type === "image"  ? "85vh" : "70vh"};
        @media screen and (max-width: ${props => props.theme.breakpoints.desktop}) {
            width: 100vw;
            height: auto;
        }
        
    }
    &>div>div:last-of-type{
        opacity: ${props => props.show ? "1" : "0"};
        // transform: ${props => props.show ? "translateY(0px)" : "translateY(56px)"};
        transition: 500ms ease-in-out;
        transition-delay: ${props => props.show ? "950ms" : "600ms"};
    }
`

export const ExitToggle = styled.svg`
    position: absolute;
    top: 40px;
    right: 48px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    transition: 400ms ease-in-out;
    // transform: ${props => props.show ? "translateX(0) rotate(0)" : " translateX(144px) rotate(130deg)"};
    transform: ${props => props.show ? "translateX(0)" : " translateX(144px)"};
    transition-delay: ${props => props.show ? "720ms" : "0"} ;

`


import styled, { withTheme } from "styled-components";

const Wrapper = styled.div`
    padding: 0 280px;
    width: 100%;
    padding-top: 180px;
    box-sizing: border-box;
    @media (max-width: ${props => props.theme.breakpoints.desktopXLarge}){
        padding: 0 208px;
        padding-top: 172px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopLarge}){
        padding: 0 192px;
        padding-top: 172px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        padding: 0 144px;
        padding-top: 172px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        padding: 0 96px;
        padding-top: 144px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        padding: 0 40px;
        padding-top: 66px;
    }

`

const PageContentWrapper = ({children,id,theme}) => {
    return(
        <Wrapper id={id} theme={theme}>
            {children}
        </Wrapper>
    )
}

export default withTheme(PageContentWrapper);
import { withTheme } from "styled-components";
import RenderIf from "../../hoc/renderIf/renderIf";
import { HeroContainer, HeroImage, ViewMore, TextContainer } from "./heroStyles";
import { scrollToY  } from "../UtilsComponent/Utils";

const Hero = (props) =>{

    return(
        <HeroContainer isCompact={props.isCompact}>
            <HeroImage image={props.image} tablet_image={props.tablet_image} mobile_image={props.mobile_image} />
            <RenderIf condition={props.centerText} >
                <TextContainer theme={props.theme}>
                    <h2>{props.text}</h2>
                </TextContainer>
            </RenderIf>
            <RenderIf condition={props.type === 'scroll'}>
                <ViewMore onClick={() => scrollToY(document.getElementById(props.linkTarget).offsetTop, 2000, 'easeInOutQuint')} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.167" height="31.733" viewBox="937.483 1016.767 23.167 31.733">
                        <g data-name="Group 80">
                            <g data-name="Group 37">
                                <path d="m938.5 1036.93 10.569 10.57 10.57-10.57" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Path 5"/>
                                <path d="M949.07 1047.5v-30" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Line 20"/>
                            </g>
                        </g>
                    </svg>
                </ViewMore>
            </RenderIf>
        </HeroContainer>
    )
}

export default withTheme(Hero);
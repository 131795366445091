import seating from '../../assets/images/seating.webp'
import { ContentContainer, Filter, Filters, ItemsContainer } from './collectionStyles';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Purely for testing
// import { CollectionsTest } from "../../testData";
import { withTheme } from "styled-components";

import { PageContentWrapper } from '../../hoc'
import { CollectionItem, Hero, Footer } from '../../components'
import Loading from '../../components/loading/loading'

const CollectionPage = (props) => {
    let { type, category } = useParams();
    const defaultCategory = localStorage.getItem('category');
    const defaultFilter = localStorage.getItem('slug');
    const [activeFilter, setActiveFilter] = useState(defaultCategory === category && defaultFilter ? defaultFilter : "all");
    const [data, setData] = useState(null);
    const [products, setProducts] = useState([]);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/${type}/${category}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setData(data));

        fetch(`${process.env.REACT_APP_REST_API_URL}/post-type/products/${type}/${category}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => setProducts(data));
    }, []);

    useEffect(() => {
        setIsVisible(false);
        setTimeout(() => {
            setProducts([]);
            fetch(`${process.env.REACT_APP_REST_API_URL}/post-type/products/${type}/${activeFilter === 'all' ? category : activeFilter}`, {
                method: 'GET',
                mode: 'cors'
            }).then(response => response.json())
                .then(data => {
                    setProducts(data);
                    setIsVisible(true);
                });
        }, 800);
    }, [activeFilter])

    const updateFilter = (slug) => {
        setActiveFilter(slug);
        localStorage.setItem('slug', slug);
        localStorage.setItem('category', category);
    }

    if(!data) return <Loading />;

    return (
        <div>
            <Helmet>
                <title>{data.name} | James Mudge</title>
                <meta
                    name="description"
                    content={data.description}
                />
            </Helmet>
            <Hero 
                isCompact={true} 
                centerText 
                text={data.name} 
                image={data.metadata.image.url} 
            />
            <div>
                <ContentContainer theme={props.theme}>
                    <Filters>
                        {data.terms.length > 0 &&
                            <Filter theme={props.theme} active={activeFilter === "all" ? 1 : 0} onClick={() => setActiveFilter("all")} >
                                All
                            </Filter>
                        }
                        {data.terms.map((term, i) => {
                            return (
                                <Filter key={i} theme={props.theme} active={activeFilter === term.slug ? 1 : 0} onClick={() => updateFilter(term.slug)} >
                                    {term.name}
                                </Filter>
                            )
                        })}
                    </Filters>
                    {!products.length &&
                        <Loading />
                    }
                    <ItemsContainer >
                        {products.map((product, i) => {
                            return (
                                <CollectionItem
                                    slug={product.slug}
                                    title={product.title}
                                    image={product.featured_image}
                                    price={product.metadata.price}
                                    url={`/products/${product.slug}`}
                                    isVisible={isVisible}
                                    key={i}
                                />
                            )
                        })}
                    </ItemsContainer>
                </ContentContainer>
            </div>
        </div>
    );
}

export default withTheme(CollectionPage);
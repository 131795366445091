
import { Column } from "./footerColumnStyles";

const FooterColumn = ({ title, children, isHiddenMobile }) => {
    return (
        <Column isHiddenMobile={isHiddenMobile}>
            <h3>
                {title}
            </h3>
            {children}
        </Column>
    );
}

export default FooterColumn;
import { HeaderContainer, Logo, MainNavigation, MenuToggle, NavCollapseHeading, NavColumn, NavColumnContent, SecondaryNavigation, StyledLink } from "./headerStyles";

import logo from '../../assets/images/logo.png';
import instagramIcon from '../../assets/images/instagram-icon.svg';

import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router';
import { withTheme } from "styled-components";
import collection from "../../pages/collection/collection";



const Header = ({theme, rooms, collections}) => {
    const location = useLocation()

    const [isOpen, setIsOpen] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState('collections');

    const prevScrollY = useRef(0);

    const [goingUp, setGoingUp] = useState(false);
    const [hide, setHide] = useState(false);
    const [isInitial, setIsInitial] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
          const currentScrollY = window.scrollY;
          if (prevScrollY.current < currentScrollY && goingUp) {
            setGoingUp(false);
          }
          if (prevScrollY.current > currentScrollY && !goingUp) {
            setGoingUp(true);
          }
    
          prevScrollY.current = currentScrollY;

          if(goingUp || currentScrollY <= 0 ) {
            setHide(false);
          }
          else{
            if(!isOpen){
                setHide(true);
                setIsInitial(false);
            }
          }

          if(currentScrollY === 0 && isOpen === false) {
                setIsInitial(true);
          }
        };
    
        window.addEventListener("scroll", handleScroll, { passive: true });
    
        return () => window.removeEventListener("scroll", handleScroll);
      }, [goingUp, isOpen, isInitial]);


    useEffect(() => {
        setIsOpen(false)
    }, [location])

    useEffect(() => {
        setActiveSubmenu('collections')
    }, [isOpen])



    return(
        <HeaderContainer hide={hide} isInitial={isInitial} >

            <MainNavigation isOpen={isOpen} theme={theme} >
                <div>
                    <Logo isOpen={isOpen} to='/'>
                        <Link to='/'>
                            <img src={logo} alt='logo' />
                        </Link>
                        
                    </Logo>
                    <MenuToggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                        {isOpen &&
                            <span>
                                CLOSE MENU
                            </span>
                        }
                        {!isOpen &&
                            <span>
                                MENU
                            </span>
                        }
                        <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.917" height="11.917" viewBox="1765.383 61.333 11.917 11.917">
                                <g data-name="Group 33">
                                    <path d="m1776.298 62.343-9.9 9.9" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Line 16"/>
                                    <path d="m1776.05 71.995-9.404-9.404" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Line 17"/>
                                </g>
                            </svg>
                        </span>
                    </MenuToggle>
                </div>
          
            </MainNavigation>
            <SecondaryNavigation isOpen={isOpen} theme={theme} >
                <div>
                    <NavColumn>
                        <NavCollapseHeading onClick={() => setActiveSubmenu(activeSubmenu === "collections" ? null : "collections")} active={activeSubmenu === "collections" ? 1 : 0}>
                            <h3>Collections</h3>
                            <div>
                                <span></span>
                                <span></span>
                            </div>
                        </NavCollapseHeading>
                        {collections &&
                            <NavColumnContent active={activeSubmenu === 'collections' ? 1 : 0}>
                                {collections.terms.map((collection, index) => {
                                    return (
                                        <StyledLink key={index} to={`/products/collections/${collection.slug}`}>{collection.name}</StyledLink>
                                    );
                                })}
                            </NavColumnContent>
                        }
                    </NavColumn>
                    <NavColumn>
                        <NavCollapseHeading onClick={() => setActiveSubmenu(activeSubmenu === "rooms" ? null : "rooms")} active={activeSubmenu === "rooms" ? 1 : 0} >
                            <h3>Rooms</h3>
                            <div>
                                <span></span>
                                <span></span>
                            </div>
                        </NavCollapseHeading>
                        {rooms &&
                            <NavColumnContent active={activeSubmenu === 'rooms' ? 1 : 0}>
                                {rooms.terms.map((room, index) => {
                                    return (
                                        <StyledLink key={index} to={`/products/rooms/${room.slug}`}>{room.name}</StyledLink>
                                    );
                                })}
                            </NavColumnContent>
                        }
                    </NavColumn>
                    <NavColumn>
                        <NavCollapseHeading onClick={() => setActiveSubmenu(activeSubmenu === "info" ? null : "info")} active={activeSubmenu === "info" ? 1 : 0}>
                            <h3>Info</h3>
                            <div>
                                <span></span>
                                <span></span>
                            </div>
                        </NavCollapseHeading>
                        <NavColumnContent active={activeSubmenu === 'info' ? 1 : 0}>
                            <StyledLink to='/about'>
                                About
                            </StyledLink>
                            <StyledLink to='/terms'>
                                T&amp;Cs
                            </StyledLink>
                        </NavColumnContent>
                     
                    </NavColumn>
                    <NavColumn>
                    
                        <NavCollapseHeading onClick={() => setActiveSubmenu(activeSubmenu === "contact" ? null : "contact")} active={activeSubmenu === "contact" ? 1 : 0}>
                            <h3>Contact</h3>
                            <div>
                                <span></span>
                                <span></span>
                            </div>
                        </NavCollapseHeading>
                        <NavColumnContent active={activeSubmenu === 'contact' ? 1 : 0}>
                            <div className="group">
                                <div>
                                    <p>T. +27 (0)79 448 1683</p>
                                    <p>E. <a href="mailto:info@jamesmudge.com">info@jamesmudge.com</a></p>
                                </div>
                                <div>
                                    <h3>Showroom</h3>
                                    <p>1 Calcutta Street</p>
                                    <p>Paarden Eiland</p>
                                    <p>Cape Town</p>
                                    <div className="subgroup">
                                        <p>Monday - Friday</p>
                                        <p>9am - 4pm</p>
                                        <p>By appointment only</p>
                                    </div>
                                </div>
                                <div>
                                    <h3>Follow Us</h3>
                                    <a href="https://www.instagram.com/jamesmudgefurniturestudio" target="_blank">
                                        <img src={instagramIcon} alt="Instagram" width="24" height="24" />
                                    </a>
                                </div>
                            </div>
                           
                        </NavColumnContent>
                    </NavColumn>
                </div>
            </SecondaryNavigation>
            
        </HeaderContainer>
    )
}


export default withTheme(Header);
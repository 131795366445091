import styled from 'styled-components'


export const Arrow = styled.div`
    position: absolute;
    top: 50%;
    z-index: 150002;
    transform: translateY(-50%);
    transition: 400ms ease-in-out;
    // max-width: 0;
    width: 50%;
    margin-top: ${props => props.galleryHasTitles ? '-31px' : '0'};
    overflow: hidden;
    opacity: ${props => props.show ? '1' : '0'};
    transition-delay: ${props => props.show ? '1400ms' : '200ms'};
    display: flex;
    justify-content: ${props => props.position === 'left' ? 'flex-start' : 'flex-end'};
    left: ${props => props.position === 'left' ? '-70px' : 'unset'};
    right: ${props => props.position !== 'left' ? '-70px' : 'unset'};
    transform: ${props => props.position === 'left' ? 'translateY(-50%)' : 'translateY(-50%)'};
    svg{
        display: block;
        cursor: ${props => props.show ? 'pointer' : 'default'};
        transition: 300ms ease-in-out;
        transform: ${props => props.position === 'left' ? ' rotate(180deg)' : 'none'};
        path{
            stroke: ${props => props.color ? props.color : '#fff'};
        }
        &:hover{
            // transform: ${props => props.position === 'left' ? 'translateX(-24px) rotate(180deg)' : 'translateX(24px)'};
        }
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
        left: ${props => props.position === 'left' ? 0 : 'unset'};
        right: ${props => props.position !== 'left' ? 0 : 'unset'};
        transform: ${props => props.position === 'left' ? 'translateY(-50%)' : 'translateY(-50%)'};
        svg{
            height: 24px;
        }
    }
`


const GalleryArrow = ({ position='left', theme, show, action, color, galleryHasTitles}) => {
    return(
        <Arrow position={position} theme={theme} show={show} galleryHasTitles={galleryHasTitles} onClick={() => action()} color={color} >
            <svg xmlns="http://www.w3.org/2000/svg" width="133.117" height="40.917" viewBox="1218.083 538.5 133.117 40.917"><g data-name="Group 35"><path d="m1330.747 578.4 19.45-19.45-19.45-19.45" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Path 5"/><path d="M1350.15 558.95h-131.347" strokeLinejoin="round" strokeLinecap="round" stroke="#fff" fill="transparent" data-name="Line 20"/></g></svg>
        </Arrow>
    )
}

export default GalleryArrow;
import styled, {keyframes} from 'styled-components'

const bounceAnim = keyframes`
    0% {
        transform: translateX(-50%) translateY(0);
    }
    50% {
        transform: translateX(-50%) translateY(-10px);
    }
    100% {
        transform: translateX(-50%) translateY(0);
    }
`

export const HeroContainer = styled.div`
    width: 100%;
    height: ${props => props.isCompact ? '42vw' : '100vh'};
    // margin-bottom: 180px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: ${props => props.theme.breakpoints.desktop}){
        height: 80vh;
        // margin-bottom: 172px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        // height: calc(100vh - 56px);
        height: ${props => props.isCompact ? '80vh' : '100vh'};
        // margin-bottom: 144px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        height: ${props => props.isCompact ? '80vh' : '100vh'};
        // margin-bottom: 96px;
    }
   
`

export const HeroImage = styled.div`
    background-image: url(${props => props.image});
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        background-image: url(${props => props.tablet_image ? props.tablet_image : props.image});
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}){
        background-image: url(${props => props.mobile_image ? props.mobile_image : props.image});
    }

`

export const ViewMore = styled.div`
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}){
        bottom: 40px;
    }

    &:hover {
        animation: ${bounceAnim} 1.8s ease-in-out infinite;
    }
`

export const TextContainer = styled.div`
    position: relative;
    z-index: 50;
    &>*{
        font-size: 110px;
        color: #fff;
        font-family: ${props => props.theme.fonts.primary};
        font-weight: 400;
    }
    @media (max-width: ${props => props.theme.breakpoints.tablet}){
        &>*{
            font-size: 64px;
        }
    }
`